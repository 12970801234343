.merchCard {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  width: 100%;
  margin-bottom: 10px;
  border: 1px solid #cccccc;

  @media (min-width: 768px) {
    flex-direction: row;
    margin: 0 12px 20px;
    width: calc(90% - 24px);
  }

  .photoWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 180px;
    background: #f2f2f2;
    flex-shrink: 0;

    @media (min-width: 768px) {
      width: 180px;
    }
  }

  .photo {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .pendingStatus {
    text-align: center;
    padding: 0 20px;
    color: #999999;
    font-size: 13px;
    line-height: 18px;
  }

  .entry {
    padding: 24px 20px;
    width: 100%;

    @media (min-width: 768px) {
      padding: 24px;
    }
  }

  .supTitle,
  .metaTitle {
    margin-bottom: 4px;
    color: #999999;
    font-size: 13px;
    line-height: 18px;
  }

  .cardTitle {
    margin-bottom: 24px;
    color: #000000;
    font-size: 15px;
    line-height: 22px;
  }

  .meta {
    display: flex;
  }

  .metaBlock {
    display: flex;
    flex-direction: column;
    width: 50%;

    &:first-child {
      padding-right: 20px;
    }
  }

  .priceValue {
    color: #000000;
    font-size: 15px;
    line-height: 22px;
  }

  .colors {
    display: flex;
    overflow: hidden;
  }

  .colorItem {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    flex-shrink: 0;
    border: 1px solid #cccccc;

    &:not(:last-child) {
      margin-right: 8px;
    }
  }

  .iconButton {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
    border: none;
    border-radius: 50%;
    background-color: rgba($color: #ffffff, $alpha: 0.5);
    outline: none;
    color: #333333;

    &:hover {
      color: #d2ab64;
      cursor: pointer;
      background-color: rgba($color: #d2ab64, $alpha: 0.5);
    }

    &:disabled {
      cursor: not-allowed;
      color: #333333;
      opacity: 0.4;
    }

    &.edit {
      bottom: 10px;
      right: 34px;
    }

    &.remove {
      bottom: 10px;
      right: 10px;
    }
  }
}

.promoteButton {
  margin-left: 20px;
}
