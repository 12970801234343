@import 'stylesShared/scss/_base';

.imageCropper {
  width: 100%;
  height: 220px;

  @media screen and (min-width: $breakpoint_m) {
    width: 486px;
  }
}

.previewImageWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 8px;
}

.previewImage {
  width: 100%;
  height: 220px;
  object-fit: contain;
  border-radius: 4px;
  background-color: $black;

  @media screen and (min-width: $breakpoint_m) {
    width: 486px;
  }
}

.fileNameInput {
  display: none;
}

.downloadButton {
  position: absolute;
  top: 0;
  right: 0;
}

.removePreviewButton {
  position: absolute;
  right: 0;
  top: 0;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $white;
  border: 1px solid $alto;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.05), 0 1px 18px rgba(0, 0, 0, 0.03),
    0 6px 10px rgba(0, 0, 0, 0.04);
  border-radius: 28px;
  cursor: pointer;
  outline: none;

  &:hover,
  &:focus {
    border: 1px solid $black;
  }
}

.removePreviewIcon {
  margin-top: -0.6px;
  color: $black;
}

.input,
.textarea,
.datePicker,
.timeZonePicker {
  margin-bottom: 16px;
}

.switch {
  width: max-content;
}

.dropzone {
  margin-bottom: 8px;
  padding: 19px 40px;
  background: $white;
  border: 1px dashed $alto-light;
  border-radius: 28px;
  cursor: pointer;
  outline: none;

  &.error {
    border-color: $border-error;
  }

  &:hover,
  &:focus {
    border: 1px dashed $black;
  }
}

.dragArea {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dragAreaIcon {
  margin-right: 12px;
  color: #969696;
}

.dragAreaText {
  display: flex;
  font-weight: $medium_font_weight;
  letter-spacing: -0.1px;

  @include font_size(12px);
  @include line_height(18px);
}

.dragLabel {
  display: none;
  margin-right: 4px;
  color: $dusty-grey;

  @media screen and (min-width: $breakpoint_ml) {
    display: block;
  }
}

.attachLabel {
  color: $harvest-gold;
}

.imageRestrictionsText {
  margin-bottom: 26px;
  letter-spacing: -0.2px;
  color: $dove-gray;
  text-align: center;

  @include font_size(12px);
  @include line_height(18px);
}

.inputWithSwitchWrapper {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: baseline;

  @media screen and (max-width: $breakpoint_xs) {
    align-items: flex-start;
  }

  .input {
    width: auto;
  }

  .switch {
    margin: 0 24px 16px 16px;
    width: auto;

    @media screen and (max-width: $breakpoint_xs) {
      margin-top: 10px;
    }

    label {
      white-space: nowrap;

      @media screen and (max-width: $breakpoint_xs) {
        padding-left: 4px;
        padding-top: 38px;
      }
    }
  }
}

.uploadVideoButtonWrapper {
  width: 100%;
  display: flex;
}

.uploadVideoButton {
  width: 250px;
  margin: 1rem 0rem 2rem;
}

.imageButtonText {
  width: 250px;
  margin: 1rem 0rem 2rem;
}

.videoName {
  text-align: right;
  color: $black;
  display: inline-block;
  margin: 1rem 0rem 2rem;
  padding: 20px 0 0 12px;
  max-width: 270px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  @media screen and (max-width: $breakpoint_xs) {
    text-align: center;
    max-width: 100%;
    height: 1rem;
    margin: -25px 0 15px;
    padding: 0px 5px;
  }
}

.videoFileSizeErrorMessage {
  color: $maximum-red;
  margin: -25px 10px 25px 5px;

  @media screen and (max-width: $breakpoint_xs) {
    width: 100%;
    margin: -10px 5px 15px;
    padding: 0px;
  }
}

.submitButton {
  width: 100%;
}

.deleteButton {
  width: 100%;
  margin-top: 10px;
}

.thumbnailImageWrapper {
  margin: 1rem;
  text-align: center;
}

.thumbnailImage {
  border: 2px solid #d2ab64;
  border-radius: 8px;
  width: 400px;
  max-height: 400px;

  @media screen and (max-width: $breakpoint_s) {
    width: 200px;
    max-height: 200px;
  }
}

.datePickerContainer {
  margin: 20px 0;
  display: flex;
  gap: 20px;
}
