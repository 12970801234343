.imageItem {
  margin-bottom: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.invalidImageItem {
  border: 3px solid red;
}

.singleImage {
  width: 200px;
  height: 200px;
}

.inputWrapper {
  padding-top: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.inputLabel {
  width: 60px;
  padding-top: 5px;
}

.inputField {
  width: 100% !important;
}

.deleteAffiliateButton {
  width: 200px;
  margin-top: 5px;
}
