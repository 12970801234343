@import 'stylesShared/scss/_base';

.root {
  padding: 58px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 1;

  &[disabled] {
    &,
    &:hover,
    &:focus,
    &:active {
      opacity: 0.8;
      cursor: not-allowed;
      user-select: none;
      box-shadow: none;
    }
  }

  &.error {
    border: 2.5px dashed $maximum-red;
  }

  @extend %button-reset;
}

.icon {
  font-size: 58px;
  flex-shrink: 0;
}

.title {
  margin-top: 6px;
  color: $black;

  @include text-lg-medium;

  @media screen and (min-width: $breakpoint_l) {
    margin-top: 21px;

    @include text-sm-medium-uppercase;
  }
}

.description {
  color: $dove-gray;

  @include text-sm-regular;

  @media screen and (min-width: $breakpoint_l) {
    @include text-xs-regular;
  }
}

.description {
  margin-top: 8px;

  @media screen and (min-width: $breakpoint_l) {
    margin-top: 13px;
  }
}
