@import 'stylesShared/scss/_base';

.previewWrapper {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: fit-content;
  max-width: 100%;
  border-radius: 4px;
  overflow: hidden;

  &.previewWrapperWithCrop {
    width: 100%;
    max-width: 100%;
  }
}

.previewMessageWrapper {
  margin: 16px 0 0;

  p {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}

.videoPreview {
  max-width: 100%;
  height: 473px;
}

.hidden {
  display: none;
}

.cropVideoContainerWrapper {
  &[class*='reactEasyCrop_Container'] {
    position: relative;
    width: 100%;
    height: 473px;
    max-width: 100%;
    max-height: 473px;

    &.hidden {
      display: none;
    }
  }
}

.loader {
  z-index: 2;
}
