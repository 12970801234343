.root {
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
}

.optionWrapper {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 10px;

  &:not(:last-child) {
    margin-bottom: 0;
  }
}

.placementItemsLimit {
  margin: 5px 0;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: gray;
  font-size: 13px;
  line-height: 16px;
}

.optionItem {
  min-height: 90px;
  padding: 12px;
  width: 94px;
  height: 94px;
  display: flex;
  text-transform: uppercase;
  flex-direction: column;
  align-items: center;
  background: white;
  border: 1px solid #ebedf5;
  cursor: pointer;

  &.active {
    .radioIcon {
      background-image: url('/images/manage-merch/blackCheck.png');
      background-size: cover;
    }

    .optionLabel {
      font-weight: 600;
    }
  }

  &.hasPrintImage {
    background: #e4cda2;
    border-color: #e4cda2;
  }
}

.radioIcon {
  margin-bottom: 8px;
  height: 20px;
  width: 20px;
  flex-shrink: 0;
  background-image: url("data:image/svg+xml,%3Csvg width='21' height='20' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 10C1 4.7533 5.2533 0.5 10.5 0.5C15.7467 0.5 20 4.7533 20 10C20 15.2467 15.7467 19.5 10.5 19.5C5.2533 19.5 1 15.2467 1 10Z' fill='white' stroke='%23DBDBDB'/%3E%3C/svg%3E%0A");

  &.selected {
    background-image: url('/images/manage-merch/blackCheck.png');
    background-size: cover;
  }
}

.optionLabel {
  margin-bottom: 4px;
  color: black;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
}

.price {
  color: #666666;
  font-size: 11px;
  line-height: 16px;
}

.removeButton {
  color: #666666;
  padding: 0;
  margin: 4px 0 0 0;
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 11px;
  line-height: 16px;

  &:hover {
    color: #d2ab64;
  }
}
