.saveButtonContainer {
  display: flex;
  text-align: end;
  margin-bottom: 0 !important;
}

.rawVideoContainer {
  margin-top: '10px';
}

.rawUrlItem {
  margin-bottom: '5px';
  padding: '5px';
  border: '1px solid #d9d9d9';
  background: '#d9d9d9';
}

.editedVideoItemError {
  color: red;
}

.videoBlockButton {
  cursor: pointer;
}

.text {
  width: 90%;
  overflow-x: hidden;
  text-overflow: ellipsis;
  align-self: center;
}

.editedVideosList {
  .videoTag {
    margin-bottom: 5px;
    border: 1px solid #d9d9d9;
    color: #1890ff;
    max-width: 600px;
    overflow: hidden;
    cursor: pointer;
    height: 34px!important;
    align-content: center;

    .videoTagButton {
      border: none;
      background-color: transparent;
      padding: 0 10px;
    }
  }

  .videoTagError {
    border-color: red !important; // wouldn't apply without important-even with specificity
  }
}
