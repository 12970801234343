$base-padding: 15px;

.root {
  &[class*="ant-layout"] {
    min-height: 100vh;
  }
}

.header {
  position: sticky;
  top: 0;
  right: 0;
  left: 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 9;

  &[class*="ant-layout-header"] {
    padding: 0 $base-padding;
  }
}

.logoWrapper {
  position: sticky;
  display: block;
  padding: 16px;
  top: 0;
  left: 0;
  width: 200px;
  height: 64px;
  z-index: 1;
  background-color: #001529;
  transition: all 0.2s;

  [class*="ant-layout-sider-collapsed"] & {
    margin: 0 auto;
    width: 80px;
    transition: all 0.2s;
  }
}

.logo {
  width: 168px;
  height: 32px;
  background: url("/images/white-logo-with-yellow-line.svg") 0 0;
  background-size: cover;
  transition: all 0.2s;

  [class*="ant-layout-sider-collapsed"] & {
    margin: 0 12px;
    width: 24px;
    transition: all 0.2s;
  }
}

.title {
  &[class*="ant-typography"] {
    margin: 0;
    color: rgba(255, 255, 255, 0.65);
    text-transform: capitalize;
  }

  [class*="ant-layout-sider-collapsed"] & {
    font-size: 0;
  }
}

.menuTopButton {
  margin: 10px 20px 20px;
  width: calc(100% - 40px);

  [class*="ant-layout-sider-collapsed"] & {
    margin: 10px 15px 20px;
    width: calc(100% - 30px);

    [class*="anticon + span"] & {
      margin-left: 0;
    }
  }
}

.menuTopButtonTitle {
  margin-left: 4px;
  display: inline-block;
  font-size: 14px;
  transition: all ease-in 0.3s;

  [class*="ant-layout-sider-collapsed"] & {
    margin-left: 0;
    font-size: 0;
    transition: all ease-in 0.3s;
  }
}

.menu {
  min-height: 100vh;
}

.backTopCta {
  &[class*="ant-back-top"] {
    right: 16px;
    bottom: 8px;
    width: 40px;
    height: 28px;
  }
}

.backTopCtaIconWrapper {
  width: 40px;
  height: 28px;
  line-height: 28px;
  border-radius: 4px;
  background-color: rgb(16, 136, 233);
  color: rgb(255, 255, 255);
  text-align: center;
  font-size: 14px;
}

.content {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: $base-padding;
}
