.root {
  display: flex;
  flex-direction: column;
}

.dataArea {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.label {
  font-weight: bold;
  margin-bottom: 5px;
}

.value {
  margin-bottom: 10px;
}

.storesData {
  margin-bottom: 20px;
}

.talentsArea {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.exportTalentsButton {
  width: 200px;
}
