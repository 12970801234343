.root {
    width: 100%;
    align-items: stretch;
  }
  
.section {
  width: 100%;
}

.inappropriateImage {
  max-width: 500px;
}

.markSafeButton {
  margin-top: 1rem;
}
