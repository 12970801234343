.imageAndBtnWrapper,
.uploadImageWrapper {
  button {
    margin: 2rem 0;
  }
  .imagesList {
    display: flex;

    .imageItem {
      text-align: center;
      display: flex;
      flex-direction: column;
      margin-right: 1rem;

      .imageButton {
        margin: 0;
        width: 50px;
      }
    }

    .imageMainButton {
      margin: 0;
      max-width: 100px;

      &.isMain {
        display: none;
      }
    }
  }
}

.singleImage {
  width: 100px;
  height: 100px;

  &.isMain {
    border: 5px solid #d2ab64;
  }
}
