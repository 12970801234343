.streamSection {
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
}

.autoComplete {
  width: 300px;
}

.text {
  padding: 15px 0;
}
