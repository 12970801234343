.sponsorPromoMessageWrapper {
  padding: 8px;
  background-color: white;
  border: 1px solid #d9d9d9;

  &.disabledRichText {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
  }
}

.formContainer {
  display: flex;
  flex-direction: column;
}

.formContainerInsideModal {
  flex-direction: column;
}

.formFieldsContainer {
  width: 100%;
}

.formFieldsWithAffiliate {
  width: 60%;
}

.formAffiliateProducts {
  width: 40%;
}

.formAffiliateProductsInsideModal {
  width: 100%;
}

.buttonContainer {
  width: 100%;
  text-align: center;
}

.buttonBottomContainer {
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
}

.finishButtonContainer {
  text-align: right;
  margin-bottom: 0px !important;
}

.storeSection {
  padding-bottom: 30px;
}

.autoComplete {
  width: 300px;
}

.participantsWrapper {
  text-align: center;
}

.streamParticipantsTitle {
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 20px;
}

.copyLinks {
  margin: 10px 0;
}

.mentionsRoot {
  justify-content: center;

  & div {
    display: flex;
    justify-content: space-between;
  }
}

.mentionSectionTitleWrapper {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.mentionsSectionTitle {
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 20px;
}

.arrowDownIconButton {
  background-color: transparent;
  border: none!important;
}

.arrowDownIcon {
  height: 15px;
  width: 15px;
}

.mentionsContainer {
  margin-left: 37.5%;
  margin-right: 3%;
}

.filteredSlots {
  max-width: 300px;
  overflow-y: auto;
  height: 200px;
}

.container {
  display: flex;
  gap: 16px;
}

.datePicker {
  margin-bottom: 16px
}

.timeSlot {
  background-color: white;
  color: black;
  border: transparent;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.selectedTimeSlot {
  background-color: #1890FF;
  color: white;
  border: transparent;
}