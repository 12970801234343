.subtitle {
  font-weight: 600;
  padding: 10px 0;
}

.inlineInput {
  flex-grow: 1;
  max-width: calc(100% - 110px);
}

.blogText {
  display: flex;
  width: 100%;
  gap: 20px;
}

.templates {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .templateButton {
    background-color: #1890ff;
    padding: 2px 10px;
    border-radius: 10px;
    color: white;
    margin-right: 5px;
    cursor: 'pointer';
  }
}

.previewSection {
  max-width: calc(100% - 110px);
  position: relative;
  top: -15px;
  margin-bottom: 5px;
}

.previewBlog {
  border: 1px solid gainsboro;
  padding: 5px;
}

.wordCounter {
  margin-left: 'auto';
  margin-bottom: 5px;
  display: 'flex';
  position: 'relative';
  top: -15px;
  align-items: 'center';
  justify-content: 'flex-start';
  font-size: 12px;
}
