.streamTitle {
  width: 90px;
  display: inline-block;
  white-space: nowrap;
  text-align: end;
}

.streamDescription {
  // overriding ".ant-typography p" default style
  p {
    margin-bottom: 0 !important;
  }
}
