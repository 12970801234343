.root {
  display: grid;
}

.label {
  font-weight: bold;
  margin-bottom: 5px;
}

.value {
  margin-bottom: 10px;
}
