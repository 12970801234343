.root {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 10px;
}

.imageWrapper {
  display: flex;
  flex-direction: column;
}

.imageContainer {
  margin-bottom: 0;

  .uploadButtons {
    margin: 5px 10px 5px 0;
  }
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  min-width: 400px;
  gap: 5px;
}

.linkWrapper {
  width: 100%;
  margin-right: 20px;
}

.inputContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;

  .input {
    width: 400px;
    margin-bottom: 5px;
  }

  .removeButton {
    width: 80px;
    filter: brightness(90%);
    background-color: red;

    &:hover,
    &:focus {
      filter: brightness(110%);
      background-color: red;
    }
  }
}

.linkContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 5px;
}

.addButton {
  width: 150px;
}

.label {
  text-align: left;
}

.submittedImages {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.link {
  max-width: calc(100% - 150px);
  word-wrap: break-word;
}
