$iPhoneWidth: 240px;
$iPhoneHeight: 512px;
$containerHeight: 340px;
$containerWidth: calc($containerHeight / $iPhoneHeight) * $iPhoneWidth;

.root {
  max-width: 750px;
}

.videoWrapper {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
}

.videoPreviewWrapper {
  width: $containerWidth;
  max-width: $containerWidth;
  height: $containerHeight;
  max-height: $containerHeight;
}

.videoPreviewVideo {
  width: $containerWidth;
  max-width: $containerWidth;
  height: $containerHeight;
  max-height: $containerHeight;
  object-fit: cover;
}

.clearButton {
  margin-top: 16px;
}
