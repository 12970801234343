.root {
  margin-bottom: 40px;
  padding: 0 16px;

  @media (min-width: 1024px) {
    padding: 0;
  }
}

.header {
  display: flex;
  justify-content: space-between;

  &.open {
    margin-bottom: 24px;
  }
}

.titleWrapper {
  display: flex;
  align-items: center;
}

.title, .count {
  font-weight: 600;
  letter-spacing: -0.2px;
  color: #000;
  font-size: 20px;
  line-height: 26px;
}

.title {
  margin-right: 16px;
}

.count {
  color: #999;
}

.toggleButton {
  margin: 0;
  padding: 0;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.chevronIcon {
  color: #000;
  flex-shrink: 0;

  &.rotate {
    transform: rotate(180deg);
  }
}

.merchList {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;

  @media (min-width: 768px) {
    margin: 0 -12px;
  }
}
