.root {
  margin: 20px 0;
  border: 1.5px solid gray;
  padding: 10px;
}

.inner {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
