$gold: #d2ab64;

.root {
  &[class^="ant-btn"] {
    &.isActive {
      background-color: $gold;
      border-color: $gold;
    }
  }
}

.icon {
  svg {
    color: $gold;
  }

  &.isActive {
    svg {
      color: white;
    }
  }
}
