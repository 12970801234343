.image {
  max-width: 200px;
}

.imagesWrapper {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.imageWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
