.imageItem {
  margin-bottom: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.invalidImageItem {
  border: 3px solid red;
}

.inputWrapper {
  padding-top: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.inputLabel {
  width: 60px;
  padding-top: 5px;
}

.inputField {
  width: 100% !important;
}

.deleteButton {
  width: 100%;
  margin-top: 5px;
}
