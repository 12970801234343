.articles {
  display: flex;
  flex-direction: column;
}

.createBtn {
  width: 220px;
  margin-right: 0px;
  margin-left: auto;
  margin-bottom: 1rem;
  text-transform: uppercase;
  padding: 10px 0;
}

.loadMoreBtn {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.detailsModalLink {
  color: #1890ff;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.published {
  margin-top: 11px;
  padding: 5px 10px;
  border-radius: 15px;
  // specified green color with opacity of 0.1
  background-color: rgba(22, 163, 74, 0.1);
  color: #1aba00;
}

.deactivated {
  margin-top: 11px;
  padding: 5px 10px;
  border-radius: 15px;
  // red color with opacity of 0.1
  background-color: rgba(255, 0, 0, 0.1);
  color: #dc2626;
}
