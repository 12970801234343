

.legendWrapper {
  padding: 0 0 8px;
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.legendItem {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #333333;
  text-transform: capitalize;
  font-size: 13px;
  line-height: 18px;

  @media screen and (min-width: 480px) {
    min-width: 114px;
  }

  &:not(:last-of-type) {
    margin: 0 16px 0 0;
  }
}

.legendItemIcon {
  margin: 0 12px 0 0;
  display: inline-block;
  width: 14px;
  height: 14px;
  border-radius: 100%;
}
