.root {
  margin: 20px;
}

.columnWrapper {
  display: flex;
  flex-direction: column;
}

.rowWrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
}

.uploadRowWrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
  margin-bottom: 30px;
}

.threeItemsRowWrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.heading {
  font-size: large;
  margin-bottom: 20px;
}

.noMarginHeading {
  font-size: large;
}

.switchWrapper {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 20px;
}

.submitButtonWrapper {
  text-align: right;
}

.dateManager {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.dateRow {
  display: flex;
  align-items: center;
  width: 100%;
}

.datePickerWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.deleteDateButton {
  margin-left: 20px;
  width: 100px;
}

.addDateButtonWrapper {
  text-align: left;
  margin-bottom: 20px;
}

.expenseWrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin: 10px 0;
  gap: 20px;

  .expenseCheckbox {
    margin-left: 10px;
  }
}

.talent {
  width: 100%;
  margin-bottom: 25px;
}
