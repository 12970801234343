.root {
  width: 100%;
  justify-content: center;
  align-items: stretch;
}

.form {
  margin: 20px auto;
  width: 100%;
  max-width: 600px;
}

.formItemTitleTipIcon {
  margin-left: 4px;
}

.formItemDescription {
  margin: 0 0 32px;
  display: block;
  font-style: italic;
}
