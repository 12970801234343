.root {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.audience {
  display: flex;
}

.platform {
  margin-left: 5px;
}
