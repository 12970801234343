@import 'stylesShared/scss/_base';

.root {
  display: flex;
  flex-direction: column;
  gap: 0;
  margin: 10px 0;
  width: 100%;
}
  
.inputWrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  z-index: 2;

  [class*="control"] {
    width: 100%;
    border: none;
    border-radius: 0px;
    word-wrap: break-word;
    box-shadow: 0 0 0 1px $light-gray;
    min-height: 56px;
    padding: 0px 16px;

    @include text-sm-medium-uppercase;
    font-weight: 400;

    &:hover, :active, :focus {
        border: none;
    }
  }

  [class*="menu"] {
    color: $harvest-gold;
    border-radius: 0px;
    word-wrap: break-word;
    align-items: center;
    margin-top: 0px;

    @include text-sm-medium-uppercase;
  }

  [class*="option"] {
    background-color: transparent;
    &:hover, :active, :focus, :default {
        border: none;
        background-color: $light-harvest-gold;
    }
  }

  [class*="multiValue"] > div:first-child {
    border-radius: 5px;
    background-color: $light-harvest-gold;
    color: $harvest-gold;
  }

  [class*="multiValue"] > div:nth-child(2) {
    background-color: $white;
  }

  [class*="multiValue"] > div:nth-child(2) > svg {
    border-radius: 10px;
    background-color: $dove-gray;
    color: $white;
    cursor: pointer;
  }

  [class*="placeholder"] {
    text-transform: none;
  }
}
  
.errorLabel {
    position: relative;
    top: 10px;
    color: $maximum-red;

    @include text-sm-regular;
}

.storesSelect {
  [class*="placeholder"] {
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    text-transform: none;
  }
  
  [class*="control"] {
    width: 100%;
    border: none;
    border-radius: 0px;
    word-wrap: break-word;
    box-shadow: 0 0 0 1px $light-gray;
    min-height: 56px;

    @include text-sm-medium-uppercase;
    font-weight: 400;

    &:hover, :active, :focus {
        border: none;
    }
  }

  [class*="menu"] {
    border-radius: 0px;
    word-wrap: break-word;
    align-items: center;
    margin-top: 0px;

    @include text-sm-medium;
    & div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      & div {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
  }

  [class*="option"] {
    background-color: transparent;
    &:hover, :active, :focus, :default {
        border: none;
        background-color: $light-harvest-gold;
    }
  }
}

.singleValue, .optionsValue {
  display: flex;
  align-items: center;
  text-transform: none;
  color: $black;
  font-size: 14px!important;
  font-weight: 400!important;
  word-wrap: break-word;
}

.name {
    margin-top: 20px;
}

.row {
    margin-top: 20px;
    display: flex;
    gap: 10px;
}

.halfRow {
    width: 50%;
}

.deleteButton {
    margin-top: 22px;
    cursor: pointer;
    border: none;
    background-color: transparent;
    color: $harvest-gold;
}

.unregisteredSelectContainer {
  display: flex;
  width: 100%;
}

.unregisteredSelect {
  [class*="control"] {
    width: 100%;
    border: none;
    border-radius: 0px;
    word-wrap: break-word;
    box-shadow: 0 0 0 1px $light-gray;
    min-height: 56px;

    @include text-sm-medium-uppercase;
    font-weight: 400;

    &:hover, :active, :focus {
        border: none;
    }
  }

  [class*="menu"] {
    border-radius: 0px;
    word-wrap: break-word;
    align-items: center;
    margin-top: 0px;

    @include text-sm-medium;
    & div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      & div {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
  }
}

.phoneNumberField {
  width: 50%;
}

.phoneNumberField > span {
  font-size: 12px;
}

.phoneNumberField > div > input {
  min-height: 58px;
}