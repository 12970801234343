.sendouts {
  display: flex;
  flex-direction: column;
}

.createBtn {
  width: 150px;
  margin-right: 0px;
  margin-left: auto;
  margin-bottom: 1rem;
}

.loadMoreBtn {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.detailsModalLink {
  color: #1890ff;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}
