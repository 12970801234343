%empty_button {
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
  outline: none;
}

.root {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 100px;
}

.designTitle {
  margin: 8px;
  font-weight: 400;
  letter-spacing: 0;
  text-transform: uppercase;
  color: #666666;
  font-size: 13px;
  line-height: 16px;
}

.designDescription {
  margin-bottom: 24px;
  font-weight: 400;
  text-align: center;
  letter-spacing: -0.1px;
  color: #666666;
  font-size: 12px;
  line-height: 18px;
}

.uploadImages {
  margin: 40px 0 40px;
  width: 100%;

  &:empty {
    margin: 20px 0;
  }
}

.uploadDesignBtn {
  width: 220px;
  height: 60px !important;
  background-color: #d2ab64 !important;
  text-transform: uppercase;
  font-weight: 600 !important;
  outline: none !important;

  &:hover {
    color: black !important;
    border: none !important;
    outline: none !important;
    cursor: pointer;
  }
  &:focus {
    color: black !important;
    border: none;
    outline: none;
  }
  &:visited {
    color: black !important;
    border: none;
    outline: none;
  }
}

.uploadImageItem {
  margin: 0 auto;
  padding: 12px 18px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: white;
  border: 1px solid #cccccc;
  max-height: 80px;
  outline: none;

  width: 400px;

  &.active {
    border: 2px solid #ac7104;

    &:hover {
      border-color: #ac7104;
    }
  }

  &:hover {
    border-color: #999999;
  }

  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.imageInfo {
  display: flex;
  align-items: center;
  overflow: hidden;
}

.image {
  margin-right: 12px;
  width: 60px;
  height: 60px;
  object-fit: cover;
}

.imageName {
  padding-right: 60px;
  font-weight: 400;
  letter-spacing: -0.2px;
  color: black;
  font-size: 16px;
  line-height: 22px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.removeIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333333;

  @extend %empty_button;

  &:hover {
    color: #ac7104;
    cursor: pointer;
  }
}
