@import 'stylesShared/scss/_base.scss';

.loader {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &.related {
    position: relative;
  }

  &:after {
    content: '';
    display: block;
    border-radius: 50%;
    animation: lds-dual-ring 1.2s linear infinite;
  }

  @each $name, $value in $interface_colors {
    &.color-#{$name} {
      &:after {
        border-style: solid;
        border-color: $value transparent $value transparent;
      }
    }
  }

  &.size {
    &-xxSmall,
    &-xSmall {
      &:after {
        width: 12px;
        height: 12px;
        border-width: 2px;
      }
    }

    &-small {
      &:after {
        width: 16px;
        height: 16px;
        border-width: 3px;
      }
    }

    &-medium {
      &:after {
        width: 20px;
        height: 20px;
        border-width: 3px;
      }
    }

    &-large {
      &:after {
        width: 26px;
        height: 26px;
        border-width: 4px;
      }
    }

    &-xLarge {
      &:after {
        width: 32px;
        height: 32px;
        border-width: 4px;
      }
    }

    &-XXLarge {
      &:after {
        width: 60px;
        height: 60px;
        border-width: 10px;
      }
    }
  }

  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}
