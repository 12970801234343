.root {
  width: 100%;
  justify-content: center;
  align-items: stretch;
}

.slider {
  margin: 20px auto;
  width: 400px;
}
