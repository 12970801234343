.card {
  width: 248px;
}

.cardImageWrapper {
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  width: 200px;
}
