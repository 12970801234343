@import 'stylesShared/scss/_base.scss';

.merchChildRoot {
  min-height: 180px;
  display: flex;
  width: 100%;
  gap: 2px;
  background-color: $white;
}

.merchInformation {
  padding: 10px 0 0 10px;
  width: 40%;
}

.backButton {
  margin: 10px;
}

.colors {
  display: flex;
  align-items: center;
  gap: 5px;
  flex-wrap: wrap;
}

.colorItem {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  flex-shrink: 0;
  border: 1px solid $alto-light;

  &:not(:last-child) {
    margin-right: 8px;
  }
}

.merchImages {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  width: 100%;
  max-height: 180px;
  flex-shrink: 0;
  border-right: 1px solid $alto-light;
  overflow-y: auto;

  @media (min-width: 768px) {
    width: 180px;
    min-width: 55%;
  }
}

.photo {
  width: 180px;
  height: 180px;
  object-fit: cover;
}
