.root {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.label {
  font-weight: bold;
  margin-bottom: 5px;
}

.budgetInputAndButton {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.budgetExplanation {
  margin: -3px 5px;
}
