.itemsWrapper {
  display: grid;
  grid-gap: 12px 8px;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 20px;
}

.designWrapper {
  width: 100%;
}

.socialAndUpdateWrapper {
  width: 100%;
  display: flex;
  gap: 10%;
}

.socialMediaPackWrapper {
  width: 50%;
}

.updateWrapper {
  margin-top: 35px;
  width: 50%;
}
