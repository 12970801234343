.redirects {
  width: 100%;

  &_empty {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;

    &__header {
      margin-bottom: 0.5em;
      width: 100%;
      display: block;
      color: rgba(0, 0, 0, 0.45);
      font-weight: 600;
      font-size: 24px;
      line-height: 1.35;
    }
  }

  &__header {
    width: 100%;
  }

  &__cta-wrapper {
    width: 100%;
    margin-bottom: 20px;
  }
}
