.root,
.leftSide {
  display: grid;
  grid-gap: 12px 8px;
  grid-template-columns: repeat(2, 1fr);
}

.image {
  padding-bottom: 20px;
  max-height: 100%;
  max-width: 100%;

  aspect-ratio: 1;
}

.shippingLabelSelect {
  width: 80%;
}
