@import 'stylesShared/scss/_base.scss';

.mergeIntoRoot {
    margin: 0;
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.mergeIntoInfo {
    height: 50px;
    display: flex;
    justify-content: center;
    margin: 10px 0;
    align-items: center;
    background: $white;
    gap: 100px;
}

.mergeIntoList {
    flex: 1;
    overflow-y: auto;
}

