.root {
  margin: 20px;
}

.columnWrapper {
  display: flex;
  flex-direction: column;
}

.rowWrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
}

.heading {
  font-size: large;
  margin-bottom: 20px;
}

.locationsHeading {
  font-size: large;
}

.campaignHeading {
  font-size: large;
  margin-top: 20px;
}

.switchWrapper {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 20px;
}

.instagramPost {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.instagramTypesWrapper {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 10px;
}

.locationsWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.location {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.locationButton {
  width: 200px;
}

.submitButtonWrapper {
  text-align: right;
}

.noItems {
  margin: 10px 0 20px;
  color: red;
  font-size: 16px;
}
