.formWrapper {
  display: grid;
  grid-gap: 12px 8px;
  grid-template-columns: repeat(2, 1fr);
}

.modalFooter {
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
}

.customInput {
  display: block;
}

.addresses {
  border: 1px solid black;
  margin: 5px 0;
  padding: 8px;
  display: grid;
  grid-gap: 12px 8px;
  grid-template-columns: repeat(3, [col-start] 1fr);
}

.priceWrapper {
  display: grid;
  grid-gap: 12px 8px;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
}
