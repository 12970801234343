.title {
  padding: 0 16px 20px 16px;
  letter-spacing: -0.4px;
  color: #000000;
  font-size: 24px;
  line-height: 32px;

  @media screen and (min-width: 1024px) {
    padding: 0 0 20px 0;
  }
}

.merchList {
  display: flex;
  flex-wrap: wrap;
  padding: 0 16px;

  @media (min-width: 768px) {
    padding: 0;
    margin: 0 -12px;
  }
}
