$white: white;
$border-light-grey: gray;
$dove-gray: gray;

.root {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.imageWrapper {
  margin: 10px 0;
}

.buttonsWrapper {
  margin: 10px 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
