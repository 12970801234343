.customTooltip {
  padding: 5px;
  background: #ffffff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 13px;
  line-height: 18px;

  .customTooltipLabel {
    margin: 0;
    padding: 0 0 10px;
    color: #333333;
  }

  .customTooltipItemsList {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .customTooltipItemsListItem {
    margin: 0 0 4px;
  }

  .customTooltipItemsListItemName {
    text-transform: capitalize;
  }
}