.root {
  &[class*="ant-layout"] {
    min-height: 100vh;
  }
}

.header {
  &[class*="ant-layout-header"] {
    padding: 16px;
  }
}

.logo {
  width: 168px;
  height: 32px;
  background: url("/images/white-logo-with-yellow-line.svg") 0 0;
  background-size: cover;
}

.content {
  &[class*="ant-layout-content"] {
    display: flex;
  }
}
