.storeSection {
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
}

.autoComplete {
  width: 300px;
}

.text {
  padding: 15px 0;
}

.accountText {
  &::before {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }
}

.customTextarea {
  &::placeholder {
    color: black !important;
  }
}

.storeSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.finishButtonContainer {
  display: flex;
  text-align: end;
  margin-bottom: 0!important;
}

.rawUrlsList {
  margin-top: '10px';
}

.textLabel {
  width: 90%;
  overflow-x: hidden;
  text-overflow: ellipsis;
}