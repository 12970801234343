@import 'stylesShared/scss/_base.scss';

.textFieldContainerWrapper {
  @extend %inputContainerWrapper;
}

.textFieldContainer {
  @extend %inputContainer;
}

.textField {
  min-width: 100%;
  max-width: 100%;
  resize: none;

  @extend %input;

  &.withResize {
    resize: auto;
  }
}

.label {
  @extend %label;
}

.errorHint {
  @extend %errorHint;
}
