.icon {
  svg {
    color: #1890ff;
  }

  &.isActive {
    svg {
      color: white;
    }
  }
}

.input {
  &[class^="ant-input-affix-wrapper"] {
    width: calc(100% - 32px);

    &.isInvalid {
      border-color: #ff4d4f;
    }
  }
}
