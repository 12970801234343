.wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.checkbox {
  margin-right: 10px;
}

.day {
  display: flex;
  flex-direction: row;
}

.checkboxItem {
  text-transform: capitalize;
}

.timeRanges {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

.timeRange {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.timezoneSelect {
  width: 80%;
}
