.root {
  justify-content: space-between;
}

.card {
  width: 200px;
  border: 2px solid #f0f0f0;
  transition: all ease-in-out 0.3s;

  &.isSelected {
    border: 2px solid #1890ff;
    transition: all ease-in-out 0.3s;
  }
}

.description {
  padding: 12px;
  display: block;
  line-height: 1.2em;
}
