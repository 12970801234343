.root {
  max-width: 300px;
  overflow: hidden;
}

.cta {
  &[class^="ant-btn"] {
    padding: 0;

    &.isActive {
      span {
        text-decoration: underline;
      }
    }
  }
}

.content {
  max-width: 100%;
  overflow: hidden;

  > [class^="ant-space-item"]:first-child {
    max-width: calc(100% - 80px);
  }
}
