@import 'stylesShared/scss/_base';

.root {
  position: relative;
  min-width: 200px;
  min-height: 100px;
}

.container {
  background-color: inherit;
}
