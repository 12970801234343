.value {
  color: #6e6d6d;
  font-weight: 400;
}

.value,
.label,
.link {
  font-size: 16px;
  line-height: 24px;
}

.label {
  color: black;
  font-weight: 500;
}

.item {
  display: flex;
  gap: 20px;
  margin: 10px 0;
}

.link {
  color: #d2ab64;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
}

.sectionTitle {
  color: black;

  font-size: 12px;
  line-height: 16px;
}

.clips {
  margin-top: 20px;
}

.tag {
  padding: 4px 12px;
  display: flex;
  align-items: center;
  background-color: white;
  color: black;
  border-radius: 16px;

  &:hover {
    cursor: pointer;
  }
}

.tag span {
  margin-right: 8px;
  color: #d2ab64;
}
