.addTalentWrapper {
  display: flex;
  flex-direction: column;
  margin: 10px 0 20px;
}

.talent {
  max-width: 600px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
