.cta {
  &[class*="ant-btn-link"] {
    padding: 4px;
    color: #000000d9;
  }
}

.ctaSecond {
  &[class*="ant-btn-link"] {
    padding: 4px 8px;
    color: #000000d9;
  }
}
