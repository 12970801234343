.stepTitle {
  margin: 16px 0 4px;
}

.stepDescription {
  padding: 0 0 16px;
  display: block;
}

.stepDescriptionInfoIcon {
  margin-left: 4px;
}

.stepsActionWrapper {
  padding: 24px 0 0;
}

.stepsActionButton {
  margin-right: 8px;
}
