@import 'stylesShared/scss/_base.scss';

$border-radius: 32px;
$transition: background 0.3s;

.radioGroup {
  list-style: none;
  padding: 4px;
  height: auto;
  position: relative;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  border-radius: $border-radius;
  background: $white;
  border: $border-secondary;
  font-weight: $semi_bold_font_weight;
  text-transform: uppercase;

  @media screen and (min-width: $breakpoint_xs) {
    height: 48px;
    flex-flow: row nowrap;

    &.long {
      height: auto;
      flex-flow: row wrap;
    }
  }

  &.small {
    height: auto;

    @media screen and (min-width: $breakpoint_xs) {
      height: 40px;

      &.long {
        height: auto;
      }
    }
  }
}

.radioGroupItemWrapper {
  margin: 6px 8px;

  @media screen and (min-width: $breakpoint_xs) {
    margin: 0;

    .radioGroup.long & {
      margin: 6px 8px;
    }
  }
}

.radioGroupItem {
  padding: 12px 20px;
  display: flex;
  align-items: center;
  border-radius: $border-radius;
  color: $dove-gray;
  font-weight: $semi_bold_font_weight;
  text-transform: uppercase;
  vertical-align: middle;
  transition: $transition;

  @include font_size(10px);
  @include line_height(14px);

  &:hover,
  &:focus {
    outline: none;
  }

  &:not(.disabled) {
    &:hover,
    &:focus {
      transition: $transition;
      cursor: pointer;
    }

    &:hover {
      background-color: transparentize($harvest-gold, 0.25);
    }

    &:focus,
    &[aria-checked='true'] {
      border-radius: $border-radius;
      background-color: $harvest-gold;
      color: $black;
      transition: $transition;
    }
  }

  &.small {
    padding: 9px 16px;
  }

  &.disabled {
    color: $alto-light;
    cursor: not-allowed;
    &[aria-checked='true'] {
      border-radius: $border-radius;
      background-color: $harvest-gold;
      color: $black;
      transition: $transition;
    }
  }
}

.label {
  text-align: left;
  display: block;
  font-style: normal;
  font-weight: normal;
  letter-spacing: -0.2px;
  color: $mine-shaft;
  margin: 0 24px 8px;

  @include font_size(14px);
  @include line_height(20px);
}

.helperText {
  text-align: left;
  display: block;
  font-style: normal;
  font-weight: normal;
  letter-spacing: -0.2px;
  color: $mine-shaft;
  margin: 0 24px 8px;

  @include font_size(14px);
  @include line_height(20px);
}
