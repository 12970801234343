.root {
  line-height: 1.15em;
  width: 100%;
}

.item {
  &[class*="ant-badge"] {
    width: 100%;
    overflow: hidden;
    font-size: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.cta {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
