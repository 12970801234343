.inlineInputAndTemplate {
  display: flex;
  width: 100%;
  gap: 20px;

  .templateButton {
    background: #1890ff;
    color: white;
    padding: 2px 10px;
    border-radius: 10px;
    margin-right: 5px;
    cursor: 'pointer';
  }
}

.inlineInput {
  flex-grow: 1;
  // template fields take roughly 200px, plus the 20 for gap
  max-width: calc(100% - 220px);
}

.bodyPreview {
  white-space: pre-wrap;
}

.hidden {
  visibility: hidden;
  height: 0px;
}

.visible {
  visibility: visible;
  height: auto;
}

.letterCounter {
  margin-left: 'auto';
  display: 'flex';
  align-items: 'center';
  justify-content: 'flex-end';
  font-size: 12px;
}
