.addButton {
    align-self: flex-end;
}

.detailsModalLink {
    color: #1890ff;
  
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  