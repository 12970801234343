.root,
.leftSide {
  display: grid;
  grid-gap: 12px 8px;
  grid-template-columns: repeat(2, 1fr);
}

.image {
  padding-bottom: 20px;
  height: 100%;
  aspect-ratio: 1;
}

.shippingLabelSelect {
  width: 80%;
}

.menuLink {
  width: 100%;
  display: block;
  padding: 5px;
  color: black !important;
}
