.root {
  margin: 20px;
}

.columnWrapper {
  display: flex;
  flex-direction: column;
}

.heading {
  font-size: large;
  margin-bottom: 20px;
}

.linkRow {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;

  .deleteButton {
    margin-left: 20px;
    width: 100px;
  }
}

.addLinkButtonWrapper {
  text-align: left;
  margin-bottom: 20px;
}

.submitButtonWrapper {
  text-align: right;
}
