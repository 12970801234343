.designAndSocialWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.designWrapper {
  width: 100%;
}

.merchCardWrapper {
  width: 100%;
}

.submitButtonWrapper {
  margin-left: 12px;
}

.socialMediaPackWrapper {
  width: 100%;
}
