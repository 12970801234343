.root {
  margin: 10px 0;
}

.label {
  margin-right: 10px;
}

.copyLinkButton {
  min-height: 45px;
}

.inputAndButtonWrapper {
  display: flex;
  flex-direction: row;
}
