@import 'stylesShared/scss/_base.scss';

.merchCompareContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}

.merchChildRoot,
.merchParentRoot {
  width: 45%;
}

.merchImages {
  display: flex;
  overflow-y: auto;
}

.imageWrapper {
  height: 300px;

  & > img {
    max-width: 200px;
  }
}

.title {
  margin-bottom: 40px;
  font-size: 18px;
}

.modalFooter {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.customInput {
  max-width: 400px;
}

.colors {
  display: flex;
  align-items: center;
  gap: 5px;
  flex-wrap: wrap;
}

.colorItem {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  flex-shrink: 0;
  border: 1px solid $alto-light;

  &:not(:last-child) {
    margin-right: 8px;
  }
}
