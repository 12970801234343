@import 'stylesShared/scss/_base';

.root {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.disabled {
  pointer-events: none;
  opacity: 0.6;
}

.millionsLabel {
  margin: 30px 0;
  color: $black;
  word-wrap: break-word;

  @include text-base-medium;
}

.arrowDownIconButton {
  width: 100%;

  [class*="buttonContent"] {
    flex-direction: column;
    gap: 10px;
  }
}

.arrowDownIcon {
  color: $harvest-gold;
}

.addNewMentionContainer {
  margin-bottom: 30px;
  width: 100%;
  display: flex!important;
  justify-content: flex-end;
}

.addNewMentionButton {
  display: flex!important;
  border: none!important;
}

.addNewMentionTitle {
  margin-left: 10px;
  text-transform: capitalize;
  color: $black;
  word-wrap: break-word;

  @include text-sm-regular;
}

.addMentionIcon {
  color: $blue!important;
}

.selectContainer {
  margin: 20px 0;
}
