.dashboardWrapper {
  padding: 0;
}

.dashboardBody {
  display: flex;
  flex-flow: column;
}

.dashboardTableTypeCol {
  text-transform: capitalize;
}