.countersCustomTabs .ant-tabs-tab-btn {
  background-color: #1890ff;
  color: #fff;
  border-radius: 3px;
  padding: 6px;
  cursor: pointer;
  white-space: nowrap;
}

.countersCustomTabs .ant-tabs-tab-active .ant-tabs-tab-btn {
  // overwrite default antd
  color: white !important;
}
