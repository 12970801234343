.linksWrapper {
  display: flex;
  flex-direction: column;
}

.downloadLink {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #1890ff;
  margin-top: 10px;
  padding: 10px;
  height: 32px;
}

.mentionsSectionRoot {
  justify-content: center;
  
  & div {
    display: flex;
    justify-content: space-between;
  }
}

.mentionSectionTitleWrapper {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.mentionsSectionTitle {
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 20px;
}

.interviewRoot {
  display: flex;
  flex-direction: column;
}

.interviewContainer {
  display: flex;
}

.interviewLeftSide {
  width: 50%;
  box-sizing: border-box;
  border-right: 0.5px solid #A7ADB4;
  padding-right: 20px;
}

.interviewRightSide {
  width: 50%;
  box-sizing: border-box;
  padding-left: 20px;
}

.saveButtonContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
  border-top: 0.5px solid #A7ADB4;
  padding-top: 20px;
}
