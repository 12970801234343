.dashboardSection {
  padding: 18px 28px 18px 18px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #e5e5e5;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

  &:not(:last-of-type) {
    margin: 0 0 24px;
  }
}

.dashboardSectionHeader {
  margin: 0 0 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @media screen and (min-width: 480px) {
    flex-direction: row;
  }
}

.dashboardSectionHeaderTitle {
  margin-bottom: 16px;
  color: #000000;
  font-weight: bold;
  letter-spacing: -0.8px;
  text-transform: uppercase;
  font-size: 26px;
  line-height: 30px;

  @media screen and (min-width: 480px) {
    margin-bottom: 0;
    font-size: 28px;
    line-height: 36px;
  }
}

.dashboardSectionHeaderSide {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  @media screen and (min-width: 480px) {
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }
}

.dashboardSectionHeaderSideTitle {
  display: none;
  color: #999999;
  font-weight: 400;
  letter-spacing: 0.4px;
  text-align: right;
  font-size: 12px;
  line-height: 16px;

  @media screen and (min-width: 480px) {
    display: block;
  }
}

.dashboardSectionHeaderSideValue {
  color: #333333;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
}
