@import 'stylesShared/scss/_base';

.root {
  display: flex;
  gap: 10px;
  margin: 20px 0;
  width: 100%;
}

.root > div {
  width: 100%;
  height: 60px;

  & div {
    height: 100%!important;
    display: flex;
    align-items: center; 
  }

  & input {
    height: 100%!important;
  }
}


.deleteButton {
  cursor: pointer;
  border: none;
  background-color: transparent;
  color: $harvest-gold;
}

.avatar {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 20px;
}

.autoCompleteWrapper {
  position: relative;
  width: 100%;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 8px 12px;
  background-color: $white;
  border: 1px solid $white;
  z-index: 1;
}

.selectMillionsMention input {
  background: transparent;
  z-index: 2;
}

.selectedValue {
  background-color: transparent;
  border: none;
  width: 100%;
  text-align: start;
}