.storeSection {
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
}

.autoComplete {
  width: 300px;
}

.text {
  padding: 15px 0;
}

.accountText {
  &::before {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }
}
