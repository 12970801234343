.root {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 10px;
}

.listItem {
  padding: 15px;
  margin: 10px 0;
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: center;
  border: 1px solid black;
}

.dateAndTime {
  display: flex;
  flex-direction: column;
}
