.root {
  justify-content: center;
}

.customColorButton {
  width: 100%;
  height: 195px;
  white-space: break-spaces;
}

.colorPickerInput {
  padding: 0;
  width: 100%;
  height: 195px;
  border: none;
  border-radius: 0;

  &.hidden {
    visibility: hidden;
    width: 0;
    height: 0;
  }
}

input.colorPickerInput[type="color" i]::-webkit-color-swatch {
  padding: 0;
  border: none;
}
