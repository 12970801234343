@import 'stylesShared/scss/_base';

%empty_button {
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
  outline: none;
}

.main {
  overflow: unset;
  background-color: white;
  padding: 10px 25px;
  min-height: calc(100% - 125px);
}

.body {
  display: grid;
  grid-template-columns: 2fr 1fr;
}

.customInput {
  div {
    max-height: 39px;
    max-width: 700px;
    border-radius: 0;
  }
}

.input {
  border-radius: 0;
}

.profitItem {
  background-color: #d3d3d3;
  max-height: 39px;
  max-width: 260px;
  display: flex;

  span {
    color: black;
  }
}

.profitBlocks {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.finishButton {
  width: 220px;
  height: 40px !important;
  text-transform: uppercase;
  font-weight: 600 !important;
}

.designTitle {
  margin-bottom: 5px;
  margin-right: 2px;
  font-weight: 400;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #666666;
  font-size: 13px;
  line-height: 16px;
}

.fee {
  height: 39px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
}

.profit {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}

// Footer
.footer {
  position: relative;
  bottom: 0;
  width: 100%;
  padding: 15px 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  background-color: white;
  box-shadow: 0px -1px 8px rgba(0, 0, 0, 0.05), 0px -3px 3px rgba(0, 0, 0, 0.03),
    0px -3px 4px rgba(0, 0, 0, 0.04);
  z-index: 9;

  .priceValue {
    font-weight: 700;
    display: flex;
    align-items: center;
    letter-spacing: -0.8px;
    text-transform: uppercase;
    color: black;
    font-size: 16px;
    line-height: 18px;
  }

  .finalPrice {
    margin-top: 16px;
  }

  .priceLabel {
    padding-right: 10px;
    margin-left: 12px;
    font-weight: 400;
    letter-spacing: -0.2px;
    color: black;
    text-transform: none;
    white-space: nowrap;
    font-size: 16px;
    line-height: 18px;
  }
}

.previewButton {
  position: absolute;
  top: 40px;
  right: 20px;
  z-index: 2;
}

.canvasWrapper {
  position: relative;
  width: 516px;
  height: 522px;
}

.selectedPlacementWrapper {
  max-width: 650px;
  height: 60px;
  border: 1px solid #cccccc;
  border-bottom: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.selectedPlacementWrapperTitle {
  color: black;
  padding-left: 20px;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 18px;
}

.canvasAndPlacementWrapper {
  display: grid;
  width: 650px;
  border: 1px solid #cccccc;
  grid-template-columns: repeat(2, 1fr);
}

.lifestyleTitleAndImageWrapper {
  margin-top: 10px;
  width: 650px;
  border: 1px solid #cccccc;
}

.lifestyleTitle {
  width: 650px;
  height: 60px;
  padding-left: 20px;
  line-height: 18px;
  font-size: 16px;
  border-bottom: 1px solid #cccccc;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: black;
  text-transform: uppercase;
}

.imageWrapper {
  padding-left: 20px;
}

.imageUploader {
  margin: -10px 0px 0px;
}

.imageUploaderExtended {
  padding-bottom: 20px;
}

.header {
  position: relative;
  padding: 10px 25px 10px;
  display: grid;
  grid-template-columns: 2fr 4fr 4fr 1fr;
  align-items: center;
  background-color: white;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.05), 0px 3px 3px rgba(0, 0, 0, 0.03),
    0px 3px 4px rgba(0, 0, 0, 0.04);
}

.headerTitle {
  padding-right: 20px;
  font-weight: 600;
  letter-spacing: -0.2px;
  color: black;
  font-size: 20px;
  line-height: 26px;
}

.headerButtonContainer {
  display: flex;
  flex-wrap: wrap;
  max-width: 80vw;
  gap: 20px;
}

.headerActiveButton {
  background-color: #d2ab64 !important;
}

.headerInactiveButton {
  background-color: #f2f2f2 !important;
}

.productNameField {
  & input {
    height: 58px;
    margin-bottom: 20px;
  }
}

.tagsInput {
  & div {
    padding: 0;
  }
}

.closeButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  background-color: transparent;
  border: none;
  outline: none;
  color: black;

  &:hover,
  &:focus {
    cursor: pointer;
    color: #d2ab64;
  }
}

.printImagesWrapper {
  margin-left: 20px;
  background-color: white;
  display: flex;
}

.placementAndCanvasWrapper {
  overflow: unset;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background: #0000002e;
}

.closeButtonContainer {
  width: 100%;
  display: flex;
  align-items: right;
  justify-content: flex-end;
  height: 0;
}

.cta {
  z-index: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  background-color: transparent;
  border: none;
  outline: none;
  color: #000000;
  width: 30px;
  height: 30px;
  font-size: 25px;
  margin-top: 10px;
  margin-right: 20px;

  &:hover,
  &:focus {
    cursor: pointer;
    color: #d2ab64;
  }
}

.customImagesButtonText {
  padding-top: 10px;
  margin-bottom: -30px;
}

.genderAndColorPicker {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.genderContainer {
  display: flex;
  gap: 20px;
}
.genderOptions {
  display: flex;
  flex-direction: row;
  gap: 10px;

  .genderOption,
  .selectedGender {
    padding: 0 10px;
    border: none;

    :focus {
      border: none;
      color: black;
      text-decoration: none;
    }

    :hover {
      color: black;
      text-decoration: none;
    }
  }

  .selectedGender {
    border-bottom: 3px solid black;
    color: black;
  }
}

.genderError {
  border-radius: 50%;
  background-color: $maximum-red;
  width: 13px;
  height: 13px;
  font-size: 11px;
  line-height: 11px;
  padding-top: 1px;
  color: $white;
  margin-left: 2px;
}

.genderTabNotification {
  padding-top: 7px;
  font-size: 12px;
}

.hatTitle {
  font-size: 18px;
}

.colorSelector {
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
}

.addColorContainer {
  display: flex;
  flex-direction: column;
  font-size: 16px;
}

.addColorIcon {
  font-size: 26px;
  cursor: pointer;
}

.selectorColorOptions {
  margin-top: 10px;
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.hideColorOptions {
  display: none;
}

.pickedColors {
  position: absolute;
  top: 15px;
  left: 15px;
  display: flex;
  gap: 5px;
  flex-direction: column;
  z-index: 2;
}

.rashguardColorOptions {
  // pushing picked colors a bit down in case of rashguard so the "collar" pointer can be seen on the template
  top: 5px;
}

.pickedColorOptionWrapper {
  display: flex;
  flex-direction: row;
  height: 30px;
}

.colorOption {
  height: 21px;
  width: 21px;
  z-index: 1;
  border: 1px solid #cccccc;
  box-sizing: content-box;
  cursor: pointer;

  &.checked {
    margin-top: 10px;
    position: relative;
    top: -10px;

    &::after {
      position: absolute;
      content: '';
      margin: -7px 0 0 -6px;
      height: 16px;
      width: 16px;
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='6' cy='6' r='5' fill='white'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5 8.50014L3 6.50014L3.705 5.79514L5 7.08514L8.295 3.79014L9 4.50014L5 8.50014ZM6 1C3.24 1 1 3.24 1 6C1 8.76 3.24 11 6 11C8.76 11 11 8.76 11 6C11 3.24 8.76 1 6 1Z' fill='%2373C64C'/%3E%3C/svg%3E%0A");
      color: #73c64c;
    }
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.errorOption {
  border: 2px solid red;
}

.extraColors {
  display: flex;
  flex-direction: row;
  font-size: 16px;
  gap: 10px;

  .colorOption {
    &.checked {
      top: -10px;

      &::after {
        position: absolute;
        content: '';
        margin: -7px 0 0 -6px;
        height: 16px;
        width: 16px;
        background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='6' cy='6' r='5' fill='white'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5 8.50014L3 6.50014L3.705 5.79514L5 7.08514L8.295 3.79014L9 4.50014L5 8.50014ZM6 1C3.24 1 1 3.24 1 6C1 8.76 3.24 11 6 11C8.76 11 11 8.76 11 6C11 3.24 8.76 1 6 1Z' fill='%2373C64C'/%3E%3C/svg%3E%0A");
        color: #73c64c;
      }
    }
  }
}

.extraColorOptions {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.cancelButton {
  padding-left: 5px;
  font-size: 20px;
}
