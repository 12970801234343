.chartWrapper {
  line {
    &[class*="recharts-cartesian-axis-tick-line"] {
      display: none;
    }
  }

  svg {
    &[class*="recharts-surface"] {
      overflow: visible;
    }
  }
}
