.linkButton {
  color: #1890ff;

  &:hover {
    color: #1890ff;
  }

  &:focus {
    color: #1890ff;
  }
}
