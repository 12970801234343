.tipsCell {
  overflow-y: scroll;
  max-height: 120px;
  min-width: 70px;
}

.tipCellWrapper {
  display: flex;
  gap: 5px;
}

.isPaused,
.isScheduled,
.isCancelled,
.isInterrupted
.isActive
.isEnded {
  display: flex;
  justify-content: center;
  border-radius: 3px;
  padding: 5px;
}

.isPaused {
  background-color: #ffe3ca;
}

.isScheduled {
  background-color: #ffe3ca;
}

.isCancelled {
  background-color: #ff989a;
}

.isInterrupted {
  background-color: #9a9ff2;
}

.isActive {
  background-color: #caffd0;
}

.isEnded {
  background-color: #e5e5e5;
}
