.entry {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
}

.qrWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 255px;
  width: 255px;
  background-size: cover;
  background-image: url("../../../public/images/qr-code/qr-mask.png");
  background-repeat: no-repeat;
}

.tag {
  margin: 30px 0 50px;
  color: black;
}

.socialLinks {
  display: flex;
  align-items: center;
  justify-content: center;
}

.socialItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 50px;
}

.socialButton {
  margin-bottom: 12px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  margin: 0 10px;
  background: #d2ab64;
}

.itemName {
  color: black;
}
