.socialAndUpdateWrapper {
  width: 50%;
}

.designAndSocialWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.designWrapper {
  width: 100%;
}

.merchCardWrapper {
  width: 100%;
}

.submitButtonWrapper {
  margin-left: 12px;
}

.socialMediaPackWrapper {
  width: 100%;
}
.itemsWrapper {
  display: grid;
  grid-gap: 12px 8px;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 20px;
}

.designWrapper {
  width: 100%;
}

.socialAndUpdateWrapper {
  width: 100%;
  display: flex;
  gap: 10%;
}

.socialMediaPackWrapper {
  width: 50%;
}

.updateWrapper {
  width: 50%;
}
