.root {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 15px;
  border-radius: 10px;
  border: 1px solid black;
  background-color: white;
}

.verticalRoot {
  flex-direction: row;
  gap: 30px;
}

.imageWrapper {
  border-radius: 10px;
  border: 1px solid black;
  overflow: hidden;
  aspect-ratio: 1;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.verticalImageWrapper {
  height: 250px;
  width: 250px;
}

.verticalDataWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 250px;
}

.sportsWrapper {
  display: flex;
  gap: 8px;
  padding: 10px 0 5px;
}

.talentName {
  font-size: 20px;
}

.detailsSection {
  max-width: 250px;
  padding: 10px 0 10px;
}

.row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  span {
    width: 50%;
  }
}

.viewProfileButtonWrapper {
  width: 250px;
  margin-bottom: 0px;

  .viewProfileButton {
    width: 100%;
  }
}
