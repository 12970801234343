.root {
  width: 100%;
}

.input {
  max-width: 100px;
}

.selectInput {
  max-width: 200px;
}

.accountText {
  align-items: center;
  margin: 5px 10px 15px 0;
}

.autoComplete {
  width: 200px;
}

.storeSection {
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
}

.optionWrapper {
  display: flex;
  flex-direction: column;
  margin-left: 10px;

  .tag {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    border: 1px solid #d9d9d9;
    background: #d9d9d9;
    padding: 0px 5px;
  }
}
