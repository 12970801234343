.bannerWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  background: #d6c5a6;
}

.isPending,
.isCompleted,
.isCanceled,
.isProcessing {
  display: flex;
  justify-content: center;
  border-radius: 3px;
  padding: 5px;
}

.isPending {
  background-color: #ffe3ca;
}

.isCompleted {
  background-color: #caffd0;
}

.isCanceled {
  background-color: #e5e5e5;
}

.isProcessing {
  background-color: #9a9ff2;
}
