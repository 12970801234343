.root {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
}

.sendoutDataItem {
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  font-size: 14x;
}

.label {
  font-weight: 600;
}

.multilineBody {
  white-space: pre-wrap;
}

.segment {
  width: auto;
  border: 1px solid gray;
  padding: 10px 10px 0;
  margin-bottom: 20px;
}

.segmentTitle {
  font-weight: 600;
  font-size: 20px;
}
