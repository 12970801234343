@import "stylesShared/scss/_base";

.root {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
  
.inputWrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  [class*="control"] {
    width: 100%;
    border: none;
    border-radius: 0px;
    word-wrap: break-word;
    box-shadow: 0 0 0 1px $light-gray;
    min-height: 56px;
    padding: 0px 16px;

    @include text-sm-medium-uppercase;
    font-weight: 400;

    &:hover, :active, :focus {
        border: none;
    }
  }

  [class*="menu"] {
    color: $harvest-gold;
    border-radius: 0px;
    word-wrap: break-word;
    align-items: center;
    margin-top: 0px;
    z-index: 2;

    @include text-sm-medium-uppercase;
  }

  [class*="option"] {
    background-color: transparent;
    &:hover, :active, :focus, :default {
        border: none;
        background-color: $light-harvest-gold;
    }
  }

  [class*="multiValue"] > div:first-child {
    border-radius: 5px;
    background-color: $light-harvest-gold;
    color: $harvest-gold;
  }

  [class*="multiValue"] > div:nth-child(2) {
    background-color: $white;
  }

  [class*="multiValue"] > div:nth-child(2) > svg {
    border-radius: 10px;
    background-color: $dove-gray;
    color: $white;
    cursor: pointer;
  }

  [class*="placeholder"] {
    text-transform: none;
  }
}
  
.errorLabel {
    position: relative;
    top: 10px;
    color: $maximum-red;

    @include text-sm-regular;
}