.root {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.ownerData {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.value {
  margin-left: 5px;
  font-weight: 600;
}
