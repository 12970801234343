.root {
  width: 100%;
  margin: 10px 0 20px;
}

.following {
  width: 100%;
  font-size: 20px;
  font-weight: 600;
  margin-left: 10px;
}

.itemWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 8px;
}
