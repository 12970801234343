.timeRange {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.specific {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

.addTimeRangeBtn {
  margin-top: 20px;
}

.timeRangePicker {
  margin-bottom: 10px;
}
