.saveButtonContainer {
  display: flex;
  text-align: start;
  margin-bottom: 0 !important;
}

.rawVideoContainer {
  margin-top: '10px';
}

.rawUrlItem {
  margin-bottom: '5px';
  padding: '5px';
  border: '1px solid #d9d9d9';
  background: '#d9d9d9';
}

.editedVideoItemError {
  color: red;
}

.videoBlockButton {
  cursor: pointer;
}

.feedback {
  display: flex;
  flex-direction: row;
  line-height: 35px;
}

.feedbackButton {
  margin: 0 0 5px 10px;
}

.videoTag {
  margin-bottom: 5px;
  border: 1px solid #d9d9d9;
  color: #1890ff;
  max-width: 600px;
  overflow: hidden;
  cursor: pointer;
  height: 34px!important;
  align-content: center;

  .videoTagButton {
    border: none;
    background-color: transparent;
    padding: 0 10px;
  }
}
