.newInterviewBtnWrapper {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.detailsModalLink {
  color: #1890ff;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}
