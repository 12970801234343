@import 'stylesShared/scss/_base';

.imageCropper {
  width: 100%;
  height: 220px;

  @media screen and (min-width: $breakpoint_m) {
    width: 486px;
  }
}

.previewImageWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 8px;
}

.previewImage {
  width: 100%;
  height: auto;
  object-fit: contain;
  border-radius: 4px;
}

.imageContainer {
  width: 100%;
}

.thumbnailImageContainer {
  margin-top: 20px;
}

.croppedImage {
  min-height: 400px;
}

.removePreviewButton {
  position: absolute;
  right: 0;
  top: 0;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $white;
  border: 1px solid $alto;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.05), 0 1px 18px rgba(0, 0, 0, 0.03),
    0 6px 10px rgba(0, 0, 0, 0.04);
  border-radius: 28px;
  cursor: pointer;
  outline: none;

  &:hover,
  &:focus {
    border: 1px solid $black;
  }
}

.removePreviewIcon {
  margin-top: -0.6px;
  color: $black;
}

.input,
.textarea,
.datePicker,
.timeZonePicker {
  margin-bottom: 16px;
}

.switch {
  width: max-content;
}

.dropzone {
  margin-bottom: 8px;
  background: $white;
  border: 3px dashed $black;
  cursor: pointer;
  outline: none;
  height: 414px;

  &.error {
    border-color: $border-error;
  }

  &:hover,
  &:focus {
    border: 1px dashed $black;
  }
}

.thumbnailDropzone {
  width: 100%;
  height: 300px;

  @media screen and (min-width: $breakpoint_l) {
    height: 250px;
    width: 250px;
  }
}

.uploadImage {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.imageLabel {
  letter-spacing: 0.24px;
  word-wrap: break-word;
  text-transform: none;
  margin-bottom: 8px;
}

.dragArea {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dragAreaIcon {
  margin-right: 12px;
  color: #969696;
}

.dragAreaText {
  display: flex;
  font-weight: $medium_font_weight;
  letter-spacing: -0.1px;

  @include font_size(12px);
  @include line_height(18px);
}

.dragLabel {
  display: none;
  margin-right: 4px;
  color: $dusty-grey;
}

.attachLabel {
  color: $harvest-gold;
}

.imageRestrictionsText {
  margin-bottom: 26px;
  letter-spacing: -0.2px;
  color: $dove-gray;
  text-align: center;

  @include font_size(12px);
  @include line_height(18px);
}

.inputWithSwitchWrapper {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: baseline;

  .input {
    width: auto;
  }

  .switch {
    margin: 0 24px 16px 16px;
    width: auto;

    label {
      white-space: nowrap;
    }
  }
}

.uploadVideoButtonWrapper {
  width: 100%;
  display: flex;
}

.uploadVideoButton {
  width: 250px;
  margin: 1rem 0rem 2rem;
}

.imageButtonText {
  width: 250px;
  margin: 1rem 0rem 2rem;
}

.videoName {
  text-align: right;
  color: $black;
  display: inline-block;
  margin: 1rem 0rem 2rem;
  padding: 20px 0 0 12px;
  max-width: 270px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.videoFileSizeErrorMessage {
  color: $maximum-red;
  margin: -25px 10px 25px 5px;
}

.submitButton {
  width: 100%;
}

.deletePostButton {
  width: 100%;
  margin-top: 20px;
}

.deleteButton {
  position: absolute;
  right: 10px;
  top: 2px;
  display: flex;
  justify-content: flex-end;
  z-index: 1;
  width: 100%;
  margin-top: 10px;

  @extend %button-reset;
}

.thumbnailImageWrapper {
  margin: 1rem;
  text-align: center;
}

.thumbnailImage {
  border: 2px solid #d2ab64;
  border-radius: 8px;
  width: 400px;
  max-height: 400px;
}

.datePickerContainer {
  margin: 20px 0;
  display: flex;
  gap: 20px;
}

.croppedThumbnailImage {
  min-width: 100%;
}

.thumbnailDropzone {
  width: 100%;
  height: 300px;
}

.hideProgressBar {
  display: none;
}

.previewThumbnailImageWrapper {
  width: 100%;
}

.previewThumbnailImageWrapper {
  width: 100%;
}

.mentionsSection {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.arrowDownIconButton {
  background-color: transparent!important;
  border: none!important;
}

.arrowDownIcon {
  height: 15px;
  width: 15px;
}