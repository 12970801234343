@import 'stylesShared/scss/_base';

.root {
  display: flex;
  flex-direction: column;
}

.body {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: space-between;
}

.details {
  display: flex;
  flex-direction: column;
  max-width: 500px;

  .nameTextarea {
    > div > label {
      color: $black;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 1px;
      word-wrap: break-word;
      text-transform: uppercase;
    }
  }
}

.itemTitle {
  color: $dove-gray;
  @include text-xl;
  line-height: 18px;
  letter-spacing: 0.4px;
  word-wrap: break-word;
}

.itemSubTitle {
  color: $dove-gray;
  word-wrap: break-word;
  margin-bottom: 8px;

  @include text-base-medium;
}

.nameTextarea textarea {
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.28px;
  word-wrap: break-word;
}

.textarea {
  ::placeholder {
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.28px;
    word-wrap: break-word;
  }
}

.imageContainer {
  width: 100%;
  height: auto;
}

.thumbnailImageContainer {
  margin-top: 20px;
}

.previewThumbnailImageWrapper {
  width: 250px;
}

.croppedImage {
  min-height: 400px;
}

.croppedThumbnailImage {
  min-height: 250px;
  max-width: 250px;
}

.previewImage {
  width: 100%;
  height: auto;
  object-fit: contain;
  border-radius: 4px;
}

.textarea {
  margin-bottom: 16px;
}

.dropzone {
  margin-bottom: 8px;
  background: $white;
  border: 3px dashed $black;
  cursor: pointer;
  outline: none;
  height: 414px;

  &.error {
    border-color: $border-error;
  }

  &:hover,
  &:focus {
    border: 1px dashed $black;
  }
}

.thumbnailDropzone {
  height: 250px;
  width: 250px;
}

.imageLabel {
  letter-spacing: 0.24px;
  word-wrap: break-word;
  text-transform: none;
  margin-bottom: 8px;
}

.dragArea {
  display: flex;
  align-items: center;
  justify-content: center;
}

.imageLabel {
  color: $black !important;
  @include text-xs-regular;
  letter-spacing: 0.24px;
  word-wrap: break-word;
  text-transform: none;
  margin-bottom: 8px;
}

.previewRoot {
  height: 100%;
  width: 100%;
  min-height: 300px;
  min-width: 300px;
  border-radius: 0;
}

.previewRoot video {
  max-width: 300px;
  max-height: 400px;
  object-fit: contain;
}

.videoBlock {
  position: relative;
  width: 100%;
  max-width: 300px;
  max-height: 400px;
  overflow: hidden;
  border: 1px dashed $alto-light;

  &.withVideo {
    background: $black;
    border: 4.5px solid $harvest-gold;

    .uploadVideoIcon,
    .recordVideoIcon {
      color: $white;
    }
  }
}

.videoBlockButton {
  position: absolute;
  top: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @extend %button-reset;
}

.coverImageWrapper {
  margin-top: 20px;
}

.submitButton {
  text-align: right;
}
